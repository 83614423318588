$prefix: 'prio-timeline-row';

.#{$prefix} {
  display: flex;
  flex-direction: row;

  &-groupHeader {
    height: 100%;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    background-color: var(--prioCl-color-application-background-default);
    z-index: 100;
  }

  &-rowItemOuterContent {
    position: relative;
    flex: 1;
    border-bottom: 1px solid var(--prioCl-color-application-border);
  }

  &-rowItemContent {
    position: absolute;
    height: 100%;
  }

  &-rowItemContentInner {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &-suffix {
    border-bottom: 1px solid var(--prioCl-color-application-border);
    background-color: var(--prioCl-color-application-background-default);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
  }
}
