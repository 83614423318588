@main-content-prefix: ~'prio-main-content';

@filter-bar-prefix: ~'prio-filter-bar';
@filter-data-list-prefix: ~'prio-filter-data-list';

.@{main-content-prefix} {
  display: flex;
  flex-direction: column;
  padding: var(--prioCl-spacing-large) var(--prioCl-spacing-extra-large);
  gap: var(--prioCl-spacing-large);
  height: 100%;
  width: 100%;
  overflow: hidden;
  background-color: var(--prioCl-color-application-background-light);

  & .@{filter-bar-prefix},
  .@{filter-data-list-prefix} {
    border-radius: var(--prioCl-border-radius-regular);
    background-color: var(--prioCl-color-application-background-default);
    width: 100%;
  }

  & > .@{filter-bar-prefix} {
    padding: var(--prioCl-spacing-regular);
  }

  & > .@{filter-data-list-prefix} {
    flex: 1;
  }
}
