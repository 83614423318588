
.DateRangePicker {
  position: relative;
  z-index: 600;
  display: block;
}

.DateRangePickerInput__withBorder {
  border-radius: 2px;
  border: 1px solid #dbdbdb;
  display: block;
}

.CalendarDay__selected_span {
    background: #0f84eb; 
    color: white; 
    border: 1px solid #0f84eb;
}
  
.CalendarDay__selected {
    background: #106EBE;
    color: white;
    border: 1px solid #106EBE;
}
  
.CalendarDay__selected:hover {
    background: #0f73cb;
    color: white;
    border: 1px solid #0f73cb; 
}
  
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
    background: #0f84eb;
    color: white;
    border: 1px solid #0f84eb;
}

.DateInput_input__focused {
  outline: 0;
  background: #fff;
  border: 0;
  border-top: 0;
  border-right: 0;
  border-bottom: 2px solid #0f73cb;
  border-left: 0
}

.DateInput_input__small {
  font-size: 15px;
  line-height: 18px;
  letter-spacing: .2px;
  font-weight: 400;
  padding: 7px 7px 5px
}

.DateInput_input__small::placeholder{
  color: rgb(215, 215, 215);
}

.DateRangePickerInput_clearDates {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2px;
}

.DateRangePickerInput_clearDates_default:hover {
  background: transparent;
  border-radius: 0%
}

.DateRangePickerInput .DateInput__small {
  width: 45%
}

.CalendarMonth_caption {
  font-size: 15px;
}

.DateRangePickerInput__disabled {
  background: #f7f7f7!important;
}

.DateRangePickerInput__disabled .DateRangePickerInput_arrow {
  color: rgba(0, 0, 0, 0.25);
}

.DateRangePickerInput__showClearDates {
  padding-right: 0px;
}

  