$prefix: 'prio-timeline-body';

.#{$prefix} {
  position: relative;
  flex: 1;

  &-list {
    overflow-x: hidden !important;
    overflow-y: overlay !important;
  }

  &-grid {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
  }

  &-gridItem {
    height: 100%;
    border-left: 1px solid #f2f3f4;

    &:last-child {
      border-right: 1px solid var(--prioCl-color-application-border);
    }
  }

  &-gridItemStartOfHigherResolution {
    border-left: 1px solid var(--prioCl-color-application-border);
  }
}
