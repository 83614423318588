$prefix: 'prio-timeline-header';

.#{$prefix} {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;

  &-headerBarPrefixCell {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-headerBarSuffixCell {
    border-left: 1px solid var(--prioCl-color-application-border);
    border-bottom: 1px solid var(--prioCl-color-application-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-barOuter {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    height: 100%;
  }

  &-bar {
    min-height: 32px;
    flex: 1;
  }

  &-topBar {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
  }

  &-bottomBar {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    width: 100%;
    position: relative;
  }

  &-barItem {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-left: 1px solid var(--prioCl-color-application-border);
    border-bottom: 1px solid var(--prioCl-color-application-border);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-stepper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
  }

  &-header {
    font-size: var(--prioCl-font-size-small);
  }

  &-currentSection {
    min-width: 175px;
    text-align: center;
  }

  &-visibleTimeRangeStepper {
    position: absolute;
    z-index: 10;
    top: 0;
    bottom: 0;
    background: transparent;
    width: 18px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  &-pills {
    display: flex;
    justify-content: flex-end;
    flex: 1;
  }
}
