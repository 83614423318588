.prio-deprecated-design {
  .prio-checkbox-input {
    border: 1px solid var(--borders_colors_disabled);
    &:checked {
      border: none;
    }
  }
  .prio-button-default-type {
    &:not(:hover) {
      background-color: var(--prio_background_transparent);
    }
    &:hover {
      color: var(--prio_primary);
    }
  }
  .prio-button-disabled-primary {
    background-color: transparent;
  }
  .prio-virtual-table {
    font-size: 13px;
  }
  .prio-vt-action-bar-label {
    font-size: 13px;
  }
}
.newStyle {
  .ant-list-item-meta-title {
    color: var(--prioCl-color-application-typography-default);
  }
}
