$prefix: 'prio-timeline';

.#{$prefix} {
  height: 100%;
  position: relative;
  overflow: hidden;

  &-prefixColumn {
    width: 250px;
    min-height: 100%;
    border-bottom: 1px solid var(--prioCl-color-application-border);
  }
}
